<template>
	<v-card>
		<v-dialog v-model="dialog" scrollable>
			<v-card min-width="350" min-height="200" dark color="primary">
				<v-card-title class="text-h5">Detalhes</v-card-title>
				<v-divider />
				<v-card-text class="pt-5">
					<pre v-if="texto">{{texto}}</pre>
					<span v-else>Esse registro não contém nenhum texto a ser exibido</span>
				</v-card-text>
				<v-divider />
				<v-card-actions class="pa-4">
					<v-btn class="px-5" elevation="0" color="white" block light @click="dialog = false">Fechar</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-card-title class="title">
			<v-row no-gutters>
				<slot></slot>
				<template v-if="filter">
					<v-col cols="auto" class="ml-2">
						<v-btn
							:disabled="carregando"
							class="mt-1"
							color="primary"
							elevation="0"
							fab
							rounded
							x-small
							@click="listar(0)"
						>
							<v-icon>mdi-magnify</v-icon>
						</v-btn>
					</v-col>
				</template>
			</v-row>
		</v-card-title>
		<v-divider />
		<v-progress-linear indeterminate absolute :active="carregando" />
		<v-card-text>
			<v-skeleton-loader v-if="carregandoSkeleton" type="table-tbody" />
			<v-simple-table v-else-if="dados.total > 0">
				<thead>
					<tr>
						<th :class="headers[i].class || ''" v-for="(h, i) in headers" :key="`th_${i}`">{{h.nome}}</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(dado,i) in lista" :key="`tr_${i}`">
						<td v-for="(d,i) in dado" :key="`td_${i}`">
							<div v-if="headers[i].tipo == 'texto'" :class="headers[i].class || ''">
								<span v-if="typeof d == 'string'">{{d.replaceAll('/ln', '\n')}}</span>
								<span v-else>{{d}}</span>
							</div>
							<div v-else-if="headers[i].tipo == 'dinheiro'"
								:class="headers[i].class || ''"
							>{{d | formataDinheiro}}</div>
							<div v-else-if="headers[i].tipo == 'porcentagem'"
								:class="headers[i].class || ''"
							>{{d.toFixed(2)}} %</div>
							<div v-else-if="headers[i].tipo == 'decimal'"
								:class="headers[i].class || ''"
							>{{parseFloat(d).toFixed(headers[i].casas)}}</div>
							<div v-else-if="headers[i].tipo == 'contato'"
								:class="headers[i].class || ''"
							>{{d | formataContato}}</div>
							<div v-else-if="headers[i].tipo == 'cep'" :class="headers[i].class || ''">{{d | formataCep}}</div>
							<div v-else-if="headers[i].tipo == 'cpf'" :class="headers[i].class || ''">{{d | formataCpf}}</div>
							<div v-else-if="headers[i].tipo == 'link'" :class="headers[i].class || ''">
								<v-btn small color="primary" outlined block :to="`${headers[i].link}${d}`">{{d}}</v-btn>
							</div>
							<div v-else-if="headers[i].tipo == 'boolean'" :class="headers[i].class || ''">
								<div v-if="d && d != 'F' && d != 'f'">
									<v-chip
										small
										:color="headers[i].reverte ? 'error' : 'success'"
										class="font-weight-bold"
									>{{headers[i].true}}</v-chip>
								</div>
								<div v-else>
									<v-chip
										dark
										:color="headers[i].reverte ? 'success' : 'error'"
										small
										class="font-weight-bold"
									>{{headers[i].false}}</v-chip>
								</div>
							</div>
							<div v-else-if="headers[i].tipo == 'objeto'" :class="headers[i].class || ''">
								<span v-for="(c,j) in headers[i].campos" :key="`i_${j}`">
									{{d[c]}}
									<template v-if="headers[i].campos.length - 1 != j">{{headers[i].divisor}}</template>
								</span>
							</div>
							<div v-else-if="headers[i].tipo == 'condicionais'" :class="headers[i].class || ''">
								<v-chip
									dark
									:color="headers[i].condicionais.filter(v => v.condicao == d).map(v => v.cor)[0]"
									small
									class="font-weight-bold"
								>{{d}}</v-chip>
							</div>
							<div v-else-if="headers[i].tipo == 'dialog'" :class="headers[i].class || ''">
								<v-btn @click="texto = d, dialog = true" color="primary" fab x-small elevation="0">
									<v-icon>mdi-eye-plus-outline</v-icon>
								</v-btn>
							</div>
              <div v-else-if="headers[i].tipo == 'botao'" :class="headers[i].class || ''">
                <v-btn @click="texto = d, dialog = true" color="primary" fab x-small elevation="0">
                  <v-icon>mdi-check</v-icon>
                </v-btn>
              </div>
              <div v-else-if="headers[i].tipo == 'select'" :class="headers[i].class || ''">
                  <v-chip :disabled="!(headers[i].permissao)" small :style="{  border: d.idsituacao == 1 ? '2px solid #AF9500' : d.idsituacao == 2 ? '2px solid #B4B4B4' : d.idsituacao == 3 ? '2px solid #6E6352' : '1px solid #6E6352' ,width: '200px' , backgroundColor: d.idsituacao == 1 ? '#C9B037' : d.idsituacao == 2 ? '#D7D7D7' : d.idsituacao == 3 ? '#AD8A56' : 'white' }">
                    <v-icon :class="d.idsituacao == 1 ? 'pl-1 pr-11 mb-n1' : d.idsituacao == 2 ? 'pl-1 pr-11 mb-n1' : d.idsituacao == 3 ? 'pl-1 pr-9' : 'mb-n1 pl-1 pr-3'" size="20"> {{ d.idsituacao == 1 ? 'mdi-podium-gold' : d.idsituacao == 2 ? 'mdi-podium-silver' : d.idsituacao == 3 ? 'mdi-podium-bronze' : 'mdi-account-cancel' }} </v-icon>
                    <v-select
                        :items="headers[i].situacoes"
                        item-text="situacao"
                        item-value="idsituacao"
                        v-model="d.idsituacao"
                        dense
                        class="font-weight-medium white--text text--white"
                        hide-details
                        @change="atualizar(dado.idcliente,d.idsituacao)"
                    ></v-select>
                  </v-chip>
              </div>
							<div v-else>{{d}}</div>
						</td>
					</tr>
				</tbody>
			</v-simple-table>
			<div v-else class="text-center pa-2">Nenhum registro encontrado</div>
			<Paginacao
				:pgLimitInt="pgLimitGet"
				:total="dados.total"
				@atualizar="listar"
				:carregando="carregando"
				:pg="pgSelecionada"
			/>
		</v-card-text>
		<v-card-actions v-if="refresh" class="px-4 my-0 py-0">
			<small
				v-if="autoRefresh && !carregando"
				class="text--secondary text-caption"
			>Próxima atualização em: {{timeRefresh}} segundos</small>
			<v-spacer />
			<span class="d-flex mb-n3">
				<v-switch
					:loading="carregando"
					v-model="autoRefresh"
					label="Atualização automática"
					color="primary"
					class="my-0 py-0"
				></v-switch>
			</span>
		</v-card-actions>
		<v-progress-linear indeterminate absolute :active="carregando" />
	</v-card>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import Paginacao from "../Widgets/Paginacao";

export default {
	name: "Table",
	props: {
		headers: {},
		filter: {
			type: Boolean,
			default: false,
		},
		refresh: {
			type: Boolean,
			default: false,
		},
		time: {
			type: Number,
			default: 60000,
		},
		keydown: { type: Boolean },
		busca: {},
		pgLimitGet: {},
		backendDirectory: {},
    backendDirectoryUpdate:{},
	},
	components: { Paginacao },
	data: () => ({
		timeRefresh: 60,
		autoRefresh: false,
		carregando: false,
		carregandoSkeleton: true,
		dados: { total: 0 },
		dialog: false,
		texto: "",
		pgSelecionada: 0,
	}),
	computed: {
		...mapState(["backendUrl"]),
		lista() {
			return this.dados.lista.map((v) => {
				let item = {};
				Object.keys(this.headers).forEach((h) => {
					v[h] ? (item[h] = v[h]) : (item[h] = "");
				});
				return item;
			});
		},
	},
	methods: {
		listar(pg) {
			this.pgSelecionada = pg;
			this.carregando = true;
			return axios
				.post(`${this.backendUrl}${this.backendDirectory}`, {
					limit: this.pgLimitGet || 999999,
					offset: this.pgLimitGet ? this.pgLimitGet * pg : 0,
					...this.busca,
				})
				.then((res) => {
					this.dados = res.data;
					this.carregando = false;
					this.carregandoSkeleton = false;
					if (this.autoRefresh) {
						this.timeRefresh = this.time / 1000;
						setTimeout(() => {
							this.listar(0);
						}, this.time);
					}
				})
				.catch(() => {
					this.dados = { total: 0 };
					this.carregando = false;
					this.carregandoSkeleton = false;
				});
		},
    atualizar(p1, p2) {
      this.carregando = true;
      return axios
          .post(`${this.backendUrl}${this.backendDirectoryUpdate}`, {
            idcliente: p1,
            idsituacao: p2,
          })
          .then(() => {
            this.carregando = false;
            this.carregandoSkeleton = false;
            if (this.autoRefresh) {
              this.timeRefresh = this.time / 1000;
              setTimeout(() => {
                this.listar(0);
              }, this.time);
            }
          })
          .catch(() => {
            this.dados = { total: 0 };
            this.carregando = false;
            this.carregandoSkeleton = false;
          });
    },
		timerRefresh() {
			if (this.timeRefresh < 0) {
				this.timeRefresh = this.time / 1000;
			}
			setTimeout(() => {
				this.timeRefresh--;
				this.timerRefresh();
			}, 1000);
		},
		init() {
			this.timeRefresh = this.time / 1000;
			this.autoRefresh = this.refresh;
			this.listar(0);
			this.timerRefresh();
		},
	},
	watch: {
		autoRefresh: function () {
			if (this.autoRefresh) {
				this.timeRefresh = this.time / 1000;
			}
		},
		keydown: function () {
			this.listar(0);
		},
	},
	created() {
		this.init();
	},
};
</script>

<style scoped>
.title {
	height: 64px;
}

.width {
	width: 100px;
}
</style>